// Entry point for the build script in your package.json
import * as bootstrap from "bootstrap";
import Rails from "@rails/ujs";
import { GridStack } from 'gridstack';

Rails.start();

require('chart.js');
import Chart from 'chart.js/auto';
global.Chart = Chart;

global.GridStack = GridStack;

window.show_active_realms = function(data){
    if(data.length > 3) {
        document.getElementById('activerealms').innerText = data[0]+' and '+(data.length-1) + 'others';
    } else {
        document.getElementById('activerealms').innerText = data.join(',');
    }
}
